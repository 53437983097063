import { useRouter } from 'next/router';
import { useRef } from 'react';

export const useImmutableRouter = () => {
  const router = useRouter();
  const routerRef = useRef<typeof router>(router);

  if (router !== routerRef.current) {
    routerRef.current = router;
  }

  return routerRef;
};
