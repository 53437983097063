import Image from 'next/image';

export const Logo = () => {
  return (
    <div className="flex items-center text-lg" title="NotionExtensions">
      <Image
        alt="NotionExtensions"
        src="/icon-32.png"
        width={32}
        height={32}
        priority
      />
      <span className="text-gray-800">Notion</span>
      <span className="text-pink-600">Extensions</span>
    </div>
  );
};
