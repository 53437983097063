import { Logger } from './Logger';

type TrackEvent = 'notion_extensions_logo_clicked';

export class Analytics {
  static trackEvent(eventName: TrackEvent, callback?: () => void) {
    if (
      process.env.NODE_ENV === 'production' &&
      // @ts-ignore
      typeof sa_event === 'function'
    ) {
      // @ts-ignore
      sa_event(eventName, callback);
    } else {
      Logger.info('Track event', eventName);
      callback?.();
    }
  }
}
