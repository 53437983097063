import { NotionURL } from '@lib/notion/NotionURL';

export const enum QueryParameterName {
  DATABASE_ID_FROM_PARENT_FRAME = 'databaseIdFromParentFrame',
  PAGE_ID_FROM_PARENT_FRAME = 'pageIdFromParentFrame',
  URL_FROM_PARENT_FRAME = 'urlFromParentFrame',
}

export class QueryParameters {
  private parameters: URLSearchParams;

  constructor(
    init:
      | Record<string, string | string[]>
      | URLSearchParams
      | NodeJS.Dict<string | string[]>
  ) {
    this.parameters =
      init instanceof URLSearchParams ? init : new URLSearchParams(init as any);
  }

  getFirst(name: QueryParameterName): string | undefined {
    return this.getAll(name)[0];
  }

  getAll(name: QueryParameterName): string[] {
    return this.parameters.getAll(name);
  }

  setOrDelete(name: QueryParameterName, value: string | undefined): void {
    if (value === undefined) {
      this.parameters.delete(name);
    } else {
      this.parameters.set(name, value);
    }
  }

  maybeSetParametersFromParentFrame(
    urlFromParentFrame: string | undefined
  ): void {
    if (typeof urlFromParentFrame !== 'string') {
      return;
    }

    const { pageId, databaseId } = new NotionURL(urlFromParentFrame).parse();

    this.parameters.delete(QueryParameterName.URL_FROM_PARENT_FRAME);
    this.setOrDelete(QueryParameterName.PAGE_ID_FROM_PARENT_FRAME, pageId);
    this.setOrDelete(
      QueryParameterName.DATABASE_ID_FROM_PARENT_FRAME,
      databaseId
    );
  }

  toString(): string {
    return this.parameters.toString();
  }
}
