export class NotionURL {
  private url: URL;

  constructor(url: string) {
    this.url = new URL(url);
  }

  parse(): { pageId?: string; databaseId?: string } {
    if (!this.isValidURL()) {
      return {};
    }

    if (this.isPageURL()) {
      const pageId = this.url.pathname.split('-').pop();

      return {
        pageId: this.getIdWithDashes(pageId),
      };
    }

    if (this.isDatabaseURL()) {
      const databaseId = this.url.pathname.split('/').pop();
      const pageId = this.url.searchParams.get('p') ?? undefined;

      return {
        databaseId: this.getIdWithDashes(databaseId),
        pageId: this.getIdWithDashes(pageId),
      };
    }

    return {};
  }

  private isValidURL(): boolean {
    const pathNameParts = this.url.pathname.split('/');

    return pathNameParts.length >= 3;
  }

  private isPageURL(): boolean {
    const lastPathName = this.url.pathname.split('/').pop();

    if (!lastPathName) {
      return false;
    }

    return lastPathName.split('-').length > 1;
  }

  private isDatabaseURL(): boolean {
    return !this.isPageURL();
  }

  private getIdWithDashes(id: string | undefined): string | undefined {
    if (!id || id.length !== 32) {
      return;
    }

    return (
      id.substring(0, 8) +
      '-' +
      id.substring(8, 12) +
      '-' +
      id.substring(12, 16) +
      '-' +
      id.substring(16, 20) +
      '-' +
      id.substring(20)
    );
  }
}
