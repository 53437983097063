import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Layout } from '@components/Layout';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LoadingScreen } from '@components/Loading';
import { Logger } from '@lib/Logger';
import nProgress from 'nprogress';
import { Router } from 'next/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '@styles/globals.css';
import 'nprogress/nprogress.css';
import { PostMessageHandler } from '@components/PostMessageHandler';

nProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }: AppProps) {
  const getLayout =
    (Component as any).getLayout ||
    ((page: React.ReactNode) => <Layout>{page}</Layout>);
  const [status, setStatus] = React.useState<'loading' | 'ready'>('loading');

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(function (registration) {
          Logger.info(
            'Service Worker registration successful with scope: ',
            registration
          );

          setStatus('ready');
        })
        .catch(function (err) {
          Logger.info('Service Worker registration failed: ', err);
        });
    } else {
      // TODO: Handle this
      Logger.error('No service worker');
    }
  }, []);

  return (
    <>
      <Head>
        <title>NotionExtensions</title>
        <link rel="icon" href="/favicon.ico" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <PostMessageHandler />
        {status === 'loading' ? (
          <LoadingScreen />
        ) : (
          getLayout(<Component {...pageProps} />)
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer
        className="w-2/3 mx-1 my-4 ml-auto"
        position="top-right"
        draggable={false}
      />
      <style jsx global>{`
        body,
        #__next {
          height: 100vh;
          background: rgb(247, 246, 243);
        }
        #nprogress > .bar {
          background: #ec4899;
        }
        #nprogress .peg {
          box-shadow: 0 0 10px #ec4899, 0 0 5px #ec4899;
        }
        ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        ::-webkit-scrollbar {
          background: transparent;
        }
        ::-webkit-scrollbar-track {
          background: #edece9;
        }
        ::-webkit-scrollbar-thumb {
          background: #d3d1cb;
        }
      `}</style>
    </>
  );
}
