import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { MenuIcon } from '@heroicons/react/outline';
import { Menu } from '@headlessui/react';
import { offset, useFloating } from '@floating-ui/react-dom';
import { Logo } from '@components/Logo';
import { Analytics } from '@lib/Analytics';

export const Layout: React.FC = ({ children }) => {
  const router = useRouter();

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4">
        <Link href={{ pathname: '/', query: router.query }} passHref>
          <a
            onClick={() => {
              Analytics.trackEvent('notion_extensions_logo_clicked');
            }}
          >
            <Logo />
          </a>
        </Link>
        <LayoutMenu />
      </div>
      <div className="flex-1 overflow-y-auto min-h-0 px-6 py-4">{children}</div>
    </div>
  );
};

const LayoutMenu: React.FC = () => {
  const { x, y, reference, floating, strategy } = useFloating({
    strategy: 'fixed',
    placement: 'bottom-end',
    middleware: [offset(5)],
  });

  return (
    <Menu>
      <Menu.Button ref={reference}>
        <MenuIcon className="space-y-1 rounded hover:bg-neutral-200 p-1 text-gray-600 h-8" />
      </Menu.Button>
      <Menu.Items
        ref={floating}
        className="z-10 bg-white rounded flex flex-col whitespace-nowrap shadow"
        style={{ position: strategy, left: x ?? '', top: y ?? '' }}
      >
        <Menu.Item>
          <LayoutMenuItemLink href="/users/me/settings">
            ⚙️ Settings
          </LayoutMenuItemLink>
        </Menu.Item>
        <Menu.Item>
          <LayoutMenuItemLink href="/signout">🏃 Sign out</LayoutMenuItemLink>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

function LayoutMenuItemLink({
  href,
  children,
  ...props
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Link href={href} passHref>
      <a {...props} className="px-2 py-1 hover:bg-neutral-200">
        {children}
      </a>
    </Link>
  );
}
