import { QueryParameters } from '@lib/next.js/QueryParameters';
import { useImmutableRouter } from '@lib/next.js/useImmutableRouter';
import React from 'react';

export const PostMessageHandler = () => {
  const router = useImmutableRouter();

  React.useEffect(() => {
    const onMessage = (e: MessageEvent<any>) => {
      switch (e.data?.type) {
        case 'NEW_PARENT_FRAME_URL':
          const queryParams = new QueryParameters(router.current.query);
          queryParams.maybeSetParametersFromParentFrame(
            e.data.urlFromParentFrame
          );

          router.current.replace(
            `${router.current.pathname}?${queryParams.toString()}`
          );
          break;
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [router]);

  return null;
};
